import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import Dashboard from './views/Dashboard.vue'
import About from './views/About.vue'
import Forgot from './views/Forgot.vue'
import Reset from './views/Reset.vue'
import Profile from './views/Profile.vue'
import Employees from './views/Employees.vue'
import Consumers from './views/Consumers.vue'
import Settings from './views/Settings.vue'
import Booklet from './views/Booklet.vue'
import Consumption from './views/Consumption.vue'
import Financial from './views/Financial.vue'
import Account from './views/Account.vue'
import Otherfees from './views/Otherfees.vue'
import page404 from './views/Error404.vue'
import page401 from './views/Error401.vue'
import Verify from './views/Verify.vue'
import swal from 'sweetalert2';
import store from '@/vuex';
import { routeGuard } from "./guard.js"

window.Swal = swal;
Vue.use(Router)

let router = new Router({
    mode: 'history',
    linkActiveClass: "active",
    routes: [
        {
            path: '', 
            redirect: '/dashboard'
        },
        {
            path: '/login', 
            name: 'Login', 
            component: Login
        },
        {
            path: '/register', 
            name: 'Register', 
            component: Register
        },
        {
            path: '/verify', 
            name: 'Verify', 
            component: Verify,
            props: route => ({ query: route.query.code })
        },
        {
            path: '/dashboard', 
            name: 'Dashboard', 
            component: Dashboard
        },
        {
            path: '/about', 
            name: 'About',
            component: About
        },
        {
            path: '/forgot', 
            name: 'Forgot', 
            component: Forgot
        },
        {
            path: '/reset', 
            name: 'Reset', 
            component: Reset,
            props: route => ({ query: route.query.token })
        },
        {
            path: '/profile', 
            name: 'Profile', 
            component: Profile,
        },
        {
            path: '/employees', 
            name: 'Employees', 
            component: Employees,
            meta: {
                guard: true,
            },
        },
        {
            path: '/consumers', 
            name: 'Consumers', 
            component: Consumers
        },
        {
            path: '/settings', 
            name: 'Settings', 
            component: Settings
        },
        {
            path: '/booklet', 
            name: 'Booklet', 
            component: Booklet
        },
        {
            path: '/consumption', 
            name: 'Consumption', 
            component: Consumption
        },
        {
            path: '/financial', 
            name: 'Financial', 
            component: Financial
        },
        {
            path: '/account/:id', 
            name: 'Account', 
            component: Account
        },
        {
            path: '/fees/:id', 
            name: 'Otherfees', 
            component: Otherfees
        },
        {
            path: '*', 
            name: 'Error404', 
            component: page404
        },
        {
            path: '/401', 
            name: 'Error401',  
            component: page401
        },
    ]
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/verify', '/forgot', '/reset', '/loader'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        next({name: 'Login'});
    } else if (loggedIn && (to.name === 'Login' || to.name === 'Register' || to.name === 'Verify' || to.name === 'Forgot' || to.name === 'Reset')) {
        next({name: 'Dashboard'});
    } else {
        // next();
        if (to.matched.some((record) => record.meta.guard)) {
            if(!routeGuard(store.state.user.role, to.name)) {
                next("/401");
            } else {
                next()
            }
        } else {
            next()
        }
    }
})

export default router
